import Anshul from "../Assets/Teams/Anshul Khatri.webp";
import Gagandeep from "../Assets/Teams/Gagandeep Singh.webp";
import Kunal from "../Assets/Teams/Kunal Sharma.webp";
import Manika from "../Assets/Teams/Manika.webp";
import Manish from "../Assets/Teams/Manish Brijwal.webp";
import Mihir from "../Assets/Teams/Mihir Mukund.webp";
import Mukesh from "../Assets/Teams/Mukesh Kumar.webp";
import Naveen from "../Assets/Teams/Naveen Kumar.webp";
import Pooja from "../Assets/Teams/Pooja Jindal.webp";
import Rajinder from "../Assets/Teams/Rajendra Prasad.webp";
import Sagar from "../Assets/Teams/Sagar Arora.webp";
import Saksham from "../Assets/Teams/Saksham Sharma.webp";
import Shrenik from "../Assets/Teams/Shrenik Jain.webp";
// import Vikram from "../Assets/Teams/vikram.webp";

const TeamsData = [
  {
    name: "Anshul Khatri",
    thumbnail: Anshul,
  },

  {
    name: "Gagandeep Singh",
    thumbnail: Gagandeep,
  },

  {
    name: "Manish Brijwal",
    thumbnail: Manish,
  },

  {
    name: "Kunal Sharma",
    thumbnail: Kunal,
  },
  {
    name: "Manika",
    thumbnail: Manika,
  },
  {
    name: "Mihir Mukund",
    thumbnail: Mihir,
  },
  {
    name: "Naveen Kumar",
    thumbnail: Naveen,
  },

  {
    name: "Pooja Jindal",
    thumbnail: Pooja,
  },

  {
    name: "Rajinder Prasad",
    thumbnail: Rajinder,
  },

  {
    name: "Sagar Arora",
    thumbnail: Sagar,
  },

  {
    name: "Saksham Sharma",
    thumbnail: Saksham,
  },
  {
    name: "Mukesh Kumar",
    thumbnail: Mukesh,
  },

  {
    name: "Shrenik jain",
    thumbnail: Shrenik,
  },

  // {
  //   name: "Vikram Singh",
  //   thumbnail: Vikram,
  // },
];

export default TeamsData;
