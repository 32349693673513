import Hitesh from "../Assets/Board/hitesh-sir.webp";
import hemkat from "../Assets/Board/hemkat-sir.webp";
import ShobhnaDwivedi from "../Assets/Board/Shobhna-Dwivedi.webp";

export const BoardData = [
  {
    name: "Hitesh Kumar",
    thumbnail: Hitesh,
    designation: "Managing Director & Creative Director, Splat Studio",
  },
  {
    name: "Hemkant Tripathi",
    thumbnail: hemkat,
    designation: "Director & CTO, Splat Studio",
  },
  {
    name: "Shobhna Dwivedi",
    thumbnail: ShobhnaDwivedi,
    designation: "Chief Financial Officer, Splat Studio",
  },
];
