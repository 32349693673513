export const MediaCoverageData = [
  {
    partner: "Google News",
    uniqueVisitors: "372,000,000",
    description:
      "Bhuaneswar (Odisha) [India], January 15: The 18th Pravasi Bharatiya Divas, organised by Goverment of Indea at Bhubaneshwar recently, celebrated the invaluable contributions of India diaspora to the world community.",
    URL: "https://news.google.com/search?q=The%20Influence%20of%20Ram%20and%20India%20Across%20Countries&hl=en-IN&gl=IN&ceid=IN%3Aen",
  },
  {
    // logo: "https://ecdn4.wn.com/or/assets/assets/wn/img/logo.png",
    partner: "Wn.com",
    uniqueVisitors: "23,005,000",
    description:
      "Bhubaneswar, Odisha, India:  The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi,",
    URL: "https://article.wn.com/view/2025/01/15/The_Influence_of_Ram_and_India_Across_Countries_Showcased_at/",
  },
  {
    partner: "The Print",
    uniqueVisitors: "20,000,000",
    description:
      "Bhubaneswar Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi, ",
    URL: "https://theprint.in/ani-press-releases/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-2025/2446079/",
  },
  {
    // logo: "https://m.dailyhunt.in/assets/img/desktop/logo.svg?mode=pwa&ver=4.0.196",
    partner: "Daily Hunt",
    uniqueVisitors: "17,000,000",
    description:
      " Bhubaneswar Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi, ",
    URL: "https://m.dailyhunt.in/news/india/english/businesswireindia-epaper-bwireind/the+influence+of+ram+and+india+across+countries+showcased+at+pravasi+bharatiya+diwas+2025-newsid-n647688097?listname=newspaperLanding&topic=general&index=10&topicIndex=10&mode=pwa",
  },
  {
    // logo: "https://img.cdn.sortd.mobi/thetribune-sortd-pro-prod-sortd/header_branding:header_brand_logo32765b30-4d29-11ef-b3b1-05e33ffb6958",
    partner: "The Tribune",
    uniqueVisitors: "4,000,000 ",
    description:
      "Bhubaneswar (Odisha) [India], January 15: The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India's growth and global influence. Inaugurated by Prime Minister Narendra Modi, the event attracted",
    URL: "https://www.tribuneindia.com/news/advertorial-disclaimer/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-2025",
  },
  {
    // logo: "https://d3lzcn6mbbadaf.cloudfront.net/static/img/logo-white.png",
    partner: "ANI News",
    uniqueVisitors: "1,600,000 ",
    description:
      "Bhubaneswar (Odisha) [India], January 15: The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India's growth and global influence. Inaugurated by Prime Minister Narendra Modi, the event attracted",
    URL: "https://aninews.in/news/business/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-202520250115124228/",
  },
  {
    // logo: "https://i0.wp.com/www.newdelhitimes.com/wp-content/uploads/2020/01/NDT-LOGO-600x124-1.png?fit=600%2C124&quality=80&ssl=1",
    partner: "New Delhi Times",
    uniqueVisitors: "1,268,148",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi,",
    URL: "https://www.newdelhitimes.com/business-wire-india/?for=N&Value=F7L6TfdWjclZqo2nERrOjgjzJM%2ficKC5Egj%2f4ooZVX8DBwjawNMm3%2fiBWgI%3d",
  },
  {
    // logo: "https://stfe.latestly.com/images/logo.png",
    partner: "Latestly",
    uniqueVisitors: "935,000",
    description:
      "Bhubaneswar (Odisha) [India], January 15: The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India's growth and global influence. Inaugurated by Prime Minister Narendra Modi, the event attracted",
    URL: "https://www.latestly.com/agency-news/business-news-the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-2025-6566776.html#google_vignette",
  },
  {
    // logo: "https://img-cdn.thepublive.com/fit-in/580x326/filters:format(webp)/smstreet/media/agency_attachments/3LWGA69AjH55EG7xRGSA.png",
    partner: "SME street",
    uniqueVisitors: "730,000",
    description:
      "Bhubaneswar, Odisha, India Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://smestreet.in/infocus/business-wire-india-news-1518393?for=N&Value=U4do%2bSO9Y5ZqVibnlkXpTghHmD19nz1SwAh1%2fo5pPDU%2fJQjcMVfgxmwdwQI%3d",
  },

  {
    // logo: "https://www.uniindia.com/images/stripad.png",
    partner: "United News of India",
    uniqueVisitors: "600,000",
    description:
      "Bhubaneswar, Odisha, India Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.uniindia.com/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-2025/business-wire-india/news/3367777.html",
  },

  {
    // logo: "https://www.ad-hoc-news.de/img/logos/203x53xlogo_264x69.jpg.pagespeed.ic.5X98RoNlqr.webp",
    partner: "AD HOC News",
    uniqueVisitors: "588,000",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.ad-hoc-news.de/boerse/news/marktberichte/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi/66473917",
  },
  {
    partner: "Devdiscourse",
    uniqueVisitors: "564,580",
    description:
      "The 18th Pravasi Bharatiya Diwas in Bhubaneshwar highlighted India's global influence, featuring the 'Vishwaroop Ram' exhibition. Created by Splat Studio, it showcased Ramayana's impact across 20 countries, blending physical and digital artifacts.",
    URL: "https://www.devdiscourse.com/article/entertainment/3225209-vishwaroop-ram-showcasing-the-timeless-influence-of-ramayana",
  },
  {
    // logo: "https://kalkinemedia.com/resources/assets/public/img/kalkine_media_logo_svg.svg",
    partner: "Kalkine Media",
    uniqueVisitors: "519,000",
    description:
      "Business Wire IndiaThe 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth",
    URL: "https://kalkinemedia.com/in/business/businessfinance/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-2025",
  },
  {
    // logo: "https://pune.news/",
    partner: "Pune.News",
    uniqueVisitors: "474,000",
    description:
      "The 18th Pravasi Bharatiya Diwas in Bhubaneshwar highlighted India's global influence, featuring the 'Vishwaroop Ram' exhibition. Created by Splat Studio, it showcased Ramayana's impact across 20 countries, blending physical and digital artifacts.",
    URL: "https://kalkinemedia.com/in/business/businessfinance/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-2025",
  },

  {
    partner: "Lokmat Times",
    uniqueVisitors: "400,000",
    description:
      "Bhubaneswar (Odisha) [India], January 15: The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India's growth and global influence. Inaugurated by Prime Minister Narendra Modi, the event attracted",
    URL: "https://timestech.in/businesswire/?for=N&Value=XQftOK7wW9TQhGGWWt8X%2fwh0S3J9bcKPbAjvrCItqF71uQgbTQnf9t1P8gI%3d",
  },
  {
    partner: "Press Trust of India",
    uniqueVisitors: "200,180",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.ptinews.com/story/business/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-2025/2203401",
  },

  {
    partner: "Indo-Asian News Service (IANS)",
    uniqueVisitors: "137,190",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.ians.in/business-wire-detail/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-2025-15-01-2025",
  },

  {
    partner: "Business News This Week",
    uniqueVisitors: "123,400 ",
    description:
      "The 18th Pravasi Bharatiya Diwas in Bhubaneshwar highlighted India's global influence, featuring the 'Vishwaroop Ram' exhibition. Created by Splat Studio, it showcased Ramayana's impact across 20 countries, blending physical and digital artifacts.",
    URL: "https://businessnewsthisweek.com/business-wire-listing/?for=N&Value=YrYP8o2%2bX%2fG0GhbWXNGt6Ajbj5E9bKt8VwhQEjL8vbVIHgjxr5zosn1RNAI%3d",
  },

  {
    partner: "Consumer Infoline",
    uniqueVisitors: "114,960",
    description:
      "The 18th Pravasi Bharatiya Diwas in Bhubaneshwar highlighted India's global influence, featuring the 'Vishwaroop Ram' exhibition. Created by Splat Studio, it showcased Ramayana's impact across 20 countries, blending physical and digital artifacts.",
    URL: "https://consumerinfoline.com/press-release/?for=N&Value=Co9VT4Ze2VZFWP5zriFTGQgr8RRbMbuuowjE1tBjI8X0RQinvwSchKRfugI%3d",
  },

  {
    partner: "Webindia 123",
    uniqueVisitors: "113,030",
    description:
      "Bhubaneswar (Odisha) [India], January 15: The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India's growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://news.webindia123.com/news/Articles/Business/20250115/4276640.html#google_vignette",
  },
  {
    partner: "Big News Network",
    uniqueVisitors: "86,530",
    description:
      "Bhubaneswar (Odisha) [India], January 15: The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India's growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.bignewsnetwork.com/news/274944054/the-influence-of-ram-and-india-across-countries---showcased-at-pravasi-bharatiya-diwas-2025",
  },
  {
    partner: "Business Wire India",
    uniqueVisitors: " 86,530",
    description:
      "Bhubaneswar (Odisha) [India], January 15: The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India's growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.businesswireindia.com/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-2025-93138.html",
  },
  {
    partner: "Punjab News Express",
    uniqueVisitors: "77,680",
    description:
      "Bhubaneswar (Odisha) [India], January 15: The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India's growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.punjabnewsexpress.com/business-press-releases/?for=N&Value=%2fyU0mSOjWAeONXI2UmLNLwjEfvG%2fDIZBoAiVLK8JB04vugjZFMbfVJm1%2bgI%3d#google_vignette",
  },
  {
    partner: "NewZNew",
    uniqueVisitors: "49,000",
    description:
      "Bhubaneswar (Odisha) [India], January 15: The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India's growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.newznew.com/business-wire-india/?for=N&Value=dBbN0pxWFJSSoAAaSfhUWAhqqNpjQ22pPQjc%2bfd1XUCfQQjQw6B5g5mykgI%3d",
  },
  {
    partner: "Afternoon Voice",
    uniqueVisitors: "41,000",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.afternoonvoice.com/business-wire-india?for=N&Value=i3t8GXzoRZcXXqf9eLqexwjFngOQ9LA0zwiPitu2j3qj%2bQjKI%2ftu7QlSkQM%3d",
  },
  {
    partner: "The CEO Magazine",
    uniqueVisitors: "23,600",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.theceo.in/business-express?for=N&Value=tWwTvK3ulTkGNFhAkkjMJgjiR1BLElFUgAgRgn8SU4H5tgi1OgsXAPKGzgI%3d",
  },
  {
    partner: "Notable Today",
    uniqueVisitors: "23,000",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://notabletoday.blogspot.com/p/coorporate-news.html?for=N&Value=3UIpNwwuz106jtiE%2fLQw9Aj1%2bhcXV2YfKQiS2lu0kKRdQQhZot12wm2C7AM%3d",
  },
  {
    partner: "Middle East times",
    uniqueVisitors: "22,000",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.middleeasttimes.news/news/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-202520250115124222/",
  },
  {
    partner: "First News",
    uniqueVisitors: "21,650",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://firstnews.co.in/businesswireindia?for=N&Value=89G5fKKbnpl2AV%2bddYfoJwgDkaPSg3RW9whS1UOZz7t%2f0Ah6yzKdi1dzmwM%3d",
  },
  {
    partner: "Upcoming News",
    uniqueVisitors: "21,400 ",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://upcomingnews.in/businesswireindia/?for=N&Value=QlENSyvZNfrTv7O6zhzU0Qg1tkOv2z6EfAjs1oeJRmIMvwjjDJcqVc6bpAM%3d",
  },
  {
    partner: "Odia-ray",
    uniqueVisitors: "21,000",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://odia-ray.in/businesswireindia.html?for=N&Value=dv549az1Z61hta79ulRfOgiOGshux4dIYwgQydiRQ%2bP3egj6msuv0goH8gI%3d",
  },
  {
    partner: "Birmingham All News Network",
    uniqueVisitors: "21,000",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.birminghamallnewsnetwork.com/news/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-202520250115124222/",
  },
  {
    partner: "Current New",
    uniqueVisitors: "20,800",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://currentnew.in/businesswireindia?for=N&Value=1VRT8gykaWsdFeqNT0nyOQgrAxrR0NyEewilfF72x%2bcyEgihKdgjussWDgM%3d",
  },
  {
    partner: "Value News Today",
    uniqueVisitors: "20,800",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://valuenews.today/businesswireindia/?for=N&Value=OcyIuIT%2bKPgu9%2f7m61DcnQjPctr4%2b9gKewjl6gBdEgcbnwhQmJ3NJaOFEgM%3d",
  },
  {
    partner: "Srilanka Island News",
    uniqueVisitors: "20,600",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.srilankaislandnews.com/news/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-202520250115124222/",
  },
  {
    partner: "Chhattisgarh today",
    uniqueVisitors: "20,500",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "http://idslive.suhaniinfo.com/business-wire/?for=N&Value=UVJFbiejx6LvOokWmocrZggVFKWgg76v5Qg2zH9kjn6UpQgYButbZChF1QM%3d",
  },
  {
    partner: "Indore Dil se",
    uniqueVisitors: "20,400",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.chhattisgarhtoday.in/news/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-202520250115124222/",
  },
  {
    partner: "Think News Today",
    uniqueVisitors: "20,400",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://thinknews.today/businesswireindia/?for=N&Value=1hFSRilEAVITYSZ9aotaiAg2asJOPBpQ4QirAEmPkDMKHQghR0xn0InZiQM%3d",
  },
  {
    partner: "News Drinker",
    uniqueVisitors: "20,200",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://newsdrinker.me/businesswireindia/?for=N&Value=JCpa19GXIlkbJ%2fgSRtnOPgg5tpuzt6UrRwiaQJPk1iW36whzEKGLF0XzJQM%3d",
  },
  {
    partner: "News Dogs",
    uniqueVisitors: "20,100",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://newsdogs.in/businesswireindia/?for=N&Value=P3fMqBMbbUZLFTn54UWUSwjhbCgvgG%2bYTggWRHO53F3UtAhpyOo3BPM6XwM%3d",
  },
  {
    partner: "Prime Time News",
    uniqueVisitors: "20,100",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://primetimenews.co.in/businesswireindia/?for=N&Value=1y6JFJxDqW5%2f%2bhriFtwIhwgfUt3gBq9jegiVdMfTMaw0ZQifBQb7YV7uVgM%3d",
  },
  {
    partner: "London channel News",
    uniqueVisitors: "20,000",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.londonchannelnews.com/news/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-202520250115124222/",
  },
  {
    partner: "News In Asia",
    uniqueVisitors: "20,000",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://newsinasia.in/businesswireindia/?for=N&Value=%2f%2bhKB%2f%2fvRFHZ2SVNG0d0NQglrIE9TKMAywhSdPxIm9fYJwjyrD3T9cHH8gM%3d",
  },
  {
    partner: "Perfect News Live",
    uniqueVisitors: "19,300",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://perfectnews.live/businesswireindia/?for=N&Value=KpsAa6MPfbg0167a2DzNvgj3nLM3vBJAngi36tHhd6IA%2fgiy027PN50d1QM%3d",
  },
  {
    partner: "News Files",
    uniqueVisitors: "19,300",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://newsfiles.in/businesswireindia/?for=N&Value=YWD1DQ7BhnYsCOV%2bl1BpogjV6iLFOWygrgg1CFb8ZiK70AiO27yUaG%2fnnwM%3d",
  },
  {
    partner: "Pune Times Mirror",
    uniqueVisitors: "19,229",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://i.punemirror.com/bwi/?for=N&Value=aKlgHuAQvEFJeJfq%2fMOwbghCpo6R2E4ZZQh1VS5TwaP5pAjUkHpF6ekWigM%3d",
  },
  {
    partner: "Odisharay",
    uniqueVisitors: "19,000",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://odisharay.com/newsfeed.php?for=N&Value=G7v4wpVPpc31roy2Pgz8TAjobyCW%2f72CDQghhxWggXT5RQhKOEL%2bfFc8OQI%3d",
  },
  {
    partner: "British columbia times",
    uniqueVisitors: "19,000",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.britishcolumbiatimes.com/news/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-202520250115124222/",
  },
  {
    partner: "Los angeles evening despatch",
    uniqueVisitors: "19,000",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.losangeleseveningdespatch.com/news/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-202520250115124222/",
  },
  {
    partner: "Telanagana Journal",
    uniqueVisitors: "19,000",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.telanganajournal.in/news/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-202520250115124222/",
  },
  {
    partner: "US World Today",
    uniqueVisitors: "19,000",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.usworldtoday.com/news/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-202520250115124222/",
  },
  {
    partner: "Mangalore mirror",
    uniqueVisitors: " 18,934",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.mangaloremirror.com/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-2025/",
  },
  {
    partner: "News Keeda",
    uniqueVisitors: "18,700",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://newskeeda.me/businesswireindia/?for=N&Value=d8neWkAqjcNu6Vbk4mizDgh2d8apiL3VvQgkH9zH3cQLXAixHGAM%2fJfPqwM%3d",
  },
  {
    partner: "Chronicle News Live",
    uniqueVisitors: "18,600",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://chroniclesnews.live/businesswireindia?for=N&Value=XecBw4RZNuUQloNIFwWqlAh3CIqWyUD%2bDwg9%2bl1%2foOghOwjMffjmaKTmzQM%3d",
  },
  {
    partner: "Toronto Sun Times",
    uniqueVisitors: "18,600",
    description:
      "Business Wire India The 18th Pravasi Bharatiya Diwas, organized by Government of India at Bhubaneshwar recently, celebrated the invaluable contributions of the Indian diaspora to India’s growth and global influence. Inaugurated by Prime Minister Narendra Modi",
    URL: "https://www.torontosuntimes.com/news/the-influence-of-ram-and-india-across-countries-showcased-at-pravasi-bharatiya-diwas-202520250115124222/",
  },
];
